const projectsData = [
    {
      title: "Choose For Me",
      description: "A web app that randomly chooses a nearby restaurant based on user's preferences by making use of the Yelp Fusion API.",
      github_link: "https://github.com/phancthuc/chooseforme",
    },
    {
      title: "Spicy Mafia",
      description: "Based on the classic party game, Mafia is a web game that allows players to connect individually on their own mobile devices, desktop, etc. paired with automated gameplay while also promoting social interaction.",
      github_link: "https://github.com/Team-SPICY/spicy-mafia",
    },
    {
      title: "Spicy Battle",
      description: "Spicy Battle is a mobile web game that incorporates character level progression and battling monsters to encourage users to be active. All the exercises included can be performed without the need of extra workout equipment.",
      github_link: "https://github.com/phancthuc/spicy-battle",
    },
    {
      title: "Clima Weather App",
      description: "A simple Swift application that fetches weather information using the OpenWeatherMap.org API based on user location.",
      github_link: "https://github.com/phancthuc/Clima-App",

    }
 
    // Add more projects here
  ];
  
  export default projectsData;