import React, { useState } from 'react';
import projectsData from './Projects-Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import './Projects.css';
import './Transitions.css';

function Projects() {
  const [expandedProject, setExpandedProject] = useState(null);

  const handleExpand = (index) => {
    if (expandedProject === index) {
      setExpandedProject(null); // Collapse if already expanded
    } else {
      setExpandedProject(index); // Expand if not expanded
    }
  };

  const renderProjects = () => {
    return projectsData.map((project, index) => (
      <div key={index} className={`project ${expandedProject === index ? 'expanded' : ''}`}>
        <h3>{project.title} <button
          className="arrow-button"
          onClick={() => handleExpand(index)}
        >
          <FontAwesomeIcon icon={expandedProject === index ? faAngleUp : faAngleDown} />
        </button></h3>
        {expandedProject === index && (
          <p>{project.description}<br></br>
            <a href={project.github_link}>GitHub Repository</a>
          </p>

        )}

      </div>
    ));
  };

  return (
    <CSSTransition in={true} timeout={1500} classNames="fade">

      <div className='home-display'>
        <h1>My Projects</h1>
        {renderProjects()}
      </div>
    </CSSTransition>


  );
}

export default Projects;