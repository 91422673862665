import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './thucphan-logo.svg'
import './NavBar.css'


function NavBar() {
  
    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to='/' className='navbar-logo'>
                        <img src={Logo} alt="logo" style={{ width: "5vw" }} />
                    </Link>


                </div>
              
            </nav>
        </>
    )
}

export default NavBar
