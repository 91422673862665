import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Home.css';
import PDF from './Thuc_Phan_Resume.pdf'
import { CSSTransition } from 'react-transition-group';

import './Transitions.css';

function Home() {
    return (

        <CSSTransition in={true} timeout={1500} classNames="fade">

            <section className="section-home">
                <div className="center-container">
                    <h1>Hey, I'm <span className="highlight">Thuc</span>.</h1>
                    <p>I'm a <span className="highlight">developer</span>.</p>
                    <br></br>

                    <div className="btn-div-home">

                        <Link to='/projects'>My Projects</Link>
                        <a href={PDF}>Resume</a>
                        <a href='mailto:thucphan1996@gmail.com'>Email Me</a>
                    </div>
                </div>
            </section>
        </CSSTransition>

    )
}

export default Home
